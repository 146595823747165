/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2025
 */

import React from "react";
import {
    IonItem,
    IonLabel,
    IonIcon,
    IonNote,
    IonText
} from "@ionic/react";
import type {PoisType} from "../../lib/data_types/dataTypes";
import {
    cubeOutline,
    hammerOutline,
    homeOutline,
    waterOutline,
    checkmark
} from "ionicons/icons";


//interface
interface PoiListItemOperatorInterface {
    poi: PoisType;
    onOrderUpdated: any;
    index: number;
    poiCount:number;
    routeId:string;
}

const PoiListItemOperator: React.FC<PoiListItemOperatorInterface> = ({
                                                                         poi,
                                                                         index,
                                                                         routeId = '',
                                                                         onOrderUpdated = () => {},
                                                                         poiCount = 0
                                                                     }) => {
    const iconMap = (action: string) => {
        if (action === 'PICK_UP') {
            return cubeOutline;
        } else if (action === 'DELIVER') {
            return homeOutline;
        } else if (action === 'FIX') {
            return hammerOutline;
        } else if (action === 'REPAIR') {
            return waterOutline;
        } else {
            return homeOutline;
        }
    }

    const iconLabel = (action: string) => {
        if (action === 'PICK_UP') {
            return "Recoger";
        } else if (action === 'DELIVER') {
            return "Entregar"
        } else if (action === 'REPAIR') {
            return "Reparar"
        } else if (action === 'GAS') {
            return "Gasolina"
        } else {
            return action;
        }
    }

    const address = poi.order ? poi.order.shipping.address : poi.address;
    const borough = poi.order ? poi.order.shipping.borough : poi.borough;
    const zipCode = poi.order ? poi.order.shipping.zipCode : poi.zipCode;
    const isPoiClosed = poi.status === "CLOSED" ? true : false;
    return (
        <IonItem className='ion-no-padding no-padding-inline-end'
                 lines={"full"}
                 detail
                 routerLink={`/routes/${routeId}/pois/${poi._id}`}>
            <div className={'w-100'}>
                <IonItem lines={'none'}>
                    <div slot={"start"} className={"flex-row ion-align-items-center"}>
                        <IonNote>{index+1} &nbsp;</IonNote>
                        {
                            isPoiClosed ?
                                <IonIcon color="medium" icon={checkmark}/> :
                                <IonIcon icon={iconMap(poi.action)}/>
                        }
                    </div>
                    <IonLabel color={isPoiClosed ? "medium" : 'default'}>
                        <strong className={isPoiClosed ? 'line-through':''}>
                            {iconLabel(poi.action)}
                        </strong>
                        <br/>
                        <IonText>
                            {poi.order ? poi.order._id : poi.name}
                        </IonText>
                        {
                            isPoiClosed ? null :
                            <IonNote>
                                <br/>
                                {address}, {borough}, {zipCode}
                            </IonNote>
                        }
                    </IonLabel>
                </IonItem>
            </div>
        </IonItem>
    )


};
export default PoiListItemOperator;
//Todo:Remove
// if (poi?.order) {
//     return(<>
//         <IonItem lines={'none'}>
//             <IonBadge>{index+1}/{poiCount}</IonBadge>
//         </IonItem>
//
//         <IonItem lines={'none'}>
//             <IonIcon icon={iconMap(poi.action)}></IonIcon>
//             <IonLabel>{iconLabel(poi.action)}</IonLabel>
//             {/*<IonIcon icon={checkmarkCircleSharp}></IonIcon>*/}
//         </IonItem>
//         <OrderListItemOperator order={poi.order} index={index}
//                                onOrderUpdated={(order: OrderType) => onOrderUpdated(order._id)}>
//         </OrderListItemOperator>
//     </>
//     )
// }

// <IonItem className='ion-no-padding no-padding-inline-end'
//          lines={"full"}
//          detail
//          routerLink={`/routes/${routeId}/pois/${poi._id}`}>
//     <div className={'w-100'}>
//         <IonItem lines={'none'}>
//             <IonIcon icon={checkmarkCircleSharp} slot="start"></IonIcon>
//             <IonBadge>{index+1}/{poiCount}</IonBadge>
//         </IonItem>
//         <IonItem lines={'none'}>
//             <IonIcon icon={iconMap(poi.action)}></IonIcon>
//             <IonLabel>{iconLabel(poi.action)}</IonLabel>
//             {
//                 poi.status === 'CLOSED' ?
//                     <IonIcon icon={checkmarkCircleSharp} color={'primary'}></IonIcon> : null
//             }
//         </IonItem>
//         <IonItem lines={'none'}>
//             <IonLabel className='no-margin-top' slot={""}>Dirección:<br/>
//                 <small>{poi.address}, {poi.borough}, {poi.zipCode}<br/>
//                     Referencia: {poi.reference}
//                 </small>
//             </IonLabel>
//
//         </IonItem>
//         <IonItem lines={'none'}>
//             <IonLabel>
//                 Instrucciones:
//                 <br/>
//                 <small>
//                     <IonText>{poi.instructions}</IonText>
//                 </small>
//             </IonLabel>
//         </IonItem>
//     </div>
// </IonItem>